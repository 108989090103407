import React, { useState } from 'react';
import './styles/formPreview.scss'
import movil from '../../images/movil.png'


const SignaturePreview = ({ selected }) => {
  return (
    <div className={`signature-preview ${selected ? 'selected-input' : ''}`}>
      <div className="signature-box">
        <span className="signature-placeholder">Firma aquí</span>
      </div>
    </div>
  );
};

/**
 * Componente que representa una vista previa de un campo de texto.
 *
 * @component
 * @returns {JSX.Element} - React JSX que representa el componente TextPreview.
 */
const TextPreview = () => {
  return <input type="text" readOnly />;
};


/**
 * Componente que representa una vista previa de un campo de carga de foto.
 *
 * @component
 * @returns {JSX.Element} - React JSX que representa el componente UploadPreview.
 */
const UploadPreview = () => {
  return <button disabled>SUBIR FOTO</button>;
};

/**
 * Componente que representa una vista previa de un campo de correo electrónico.
 *
 * @component
 * @returns {JSX.Element} - React JSX que representa el componente EmailPreview.
 */
const EmailPreview = () => {
  return <input type="email" placeholder="usuario@ejemplo.com" readOnly />;
};

/**
 * Componente que representa una vista previa de un campo de fecha y hora.
 *
 * @component
 * @returns {JSX.Element} - React JSX que representa el componente DateTimePreview.
 */
const DateTimePreview = () => {
  return <input type="datetime-local" readOnly />;
};

/**
 * Componente que representa una vista previa de un campo numérico.
 *
 * @component
 * @returns {JSX.Element} - React JSX que representa el componente NumberPreview.
 */
const NumberPreview = () => {
  return <input type="number" readOnly />;
};

/**
 * Componente que representa una vista previa de un campo de carga de archivo.
 *
 * @component
 * @returns {JSX.Element} - React JSX que representa el componente UploadFilePreview.
 */
const UploadFilePreview = () => {
  return <button disabled>SUBIR ARCHIVO</button>;
};

/**
 * Componente que representa una vista previa de un campo de selección de lista desplegable.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.input - Configuración del campo.
 * @returns {JSX.Element} - React JSX que representa el componente DropboxPreview.
 */
const DropboxPreview = ({ input }) => {
  return (
    <select>
      {input.dropbox?.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

/**
 * Componente que representa una vista previa de un campo de selección de lista desplegable múltiple.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.input - Configuración del campo.
 * @returns {JSX.Element} - React JSX que representa el componente MultiDropboxPreview.
 */
const MultiDropboxPreview = ({ input }) => {
  return (
    <select>
      {input.dropbox?.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

/**
 * Componente que representa una vista previa de un campo de fecha.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.selected - Indica si el campo está seleccionado.
 * @returns {JSX.Element} - React JSX que representa el componente DatePreview.
 */
const DatePreview = ({ selected }) => {
  return <input type="date" readOnly className={selected ? 'selected-input' : ''} />;
};

/**
 * Componente principal que representa una vista previa de un formulario.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.formTitle - Título del formulario.
 * @param {Object} props.formInputs - Configuración de los campos del formulario.
 * @param {Function} props.onInputClick - Función de manejo al hacer clic en un campo.
 * @returns {JSX.Element} - React JSX que representa el componente FormPreview.
 */
const FormPreview = ({ formTitle, formInputs, onInputClick }) => {
  // Si formTitle está vacío, establece el valor predeterminado
  const displayedName = formTitle.trim() === '' ? 'Título' : formTitle;
  const [selectedInput, setSelectedInput] = useState(null);

  /**
   * Maneja el clic en un campo de formulario.
   *
   * @function
   * @param {string} title - Título del campo clicado.
   * @returns {void}
   */
  const handleInputClick = (title) => {
    onInputClick(title);
    setSelectedInput(title);
  };

  return (
    <div className="phone-container">
      <img src={movil} alt="Pantalla de móvil con formulario superpuesto" />
      <div className="form-preview">
        <div className="phone-screen">
          <h3>{displayedName}</h3>
          <ul>
            {Object.entries(formInputs).map(([title, input]) => (
              <li
                key={title}
                onClick={() => handleInputClick(title)}
                className={selectedInput === title ? 'selected-input-container' : ''}
              >
                <div>
                  <label>{input.name || 'label'}</label>
                  {input.type === 'UPLOAD' && <UploadPreview selected={selectedInput === title} />}
                  {input.type === 'DROPBOX' && <DropboxPreview input={input} selected={selectedInput === title} />}
                  {input.type === 'MULTI_DROPBOX' && <MultiDropboxPreview input={input} selected={selectedInput === title} />}
                  {input.type === 'DATE' && <DatePreview selected={selectedInput === title} />}
                  {input.type === 'TEXT' && <TextPreview selected={selectedInput === title} />}
                  {input.type === 'EMAIL' && <EmailPreview selected={selectedInput === title} />}
                  {input.type === 'DATE_TIME' && <DateTimePreview selected={selectedInput === title} />}
                  {input.type === 'NUMBER' && <NumberPreview selected={selectedInput === title} />}
                  {input.type === 'UPLOAD_FILE' && <UploadFilePreview selected={selectedInput === title} />}
                  {input.type === 'SIGNATURE' && <SignaturePreview selected={selectedInput === title} />}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <button className="circular-button">Enviar</button>
      </div>
    </div>
  );
};

export default FormPreview;
