import { getFirestore, collection, getDocs, addDoc, query, where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase';

const functions = getFunctions();

export const getExpoTokensForVigilantes = async (numTiendas, servicioId) => {
  try {
    console.log('Buscando tokens para:', { numTiendas, servicioId });

    const vigilantesRef = collection(db, 'servicios', servicioId, 'vigilantes');
    const vigilantesSnapshot = await getDocs(vigilantesRef);

    console.log('Total vigilantes encontrados:', vigilantesSnapshot.size);

    const tokens = [];
    vigilantesSnapshot.forEach(doc => {
      const data = doc.data();
      console.log('Procesando vigilante:', {
        id: doc.id,
        nombre: data.nombre,
        tiendas: data.tiendas?.map(t => t.numero),
        expoToken: data.expoToken,
        borrado: data.borrado
      });
      
      // Verificar si el vigilante tiene un token y está asignado a alguna de las tiendas seleccionadas
      if (data.expoToken && !data.borrado) {
        if (!numTiendas || numTiendas.includes('all')) {
          // Si no hay tiendas específicas o se seleccionaron todas
          tokens.push(data.expoToken);
          console.log('Token añadido (todas las tiendas):', data.expoToken);
        } else if (data.tiendas && Array.isArray(data.tiendas)) {
          // Verificar si el vigilante está asignado a alguna de las tiendas seleccionadas
          const vigilanteTiendas = data.tiendas.map(t => t.numero);
          const tienetiendaSeleccionada = numTiendas.some(numTienda => 
            vigilanteTiendas.includes(numTienda)
          );
          
          if (tienetiendaSeleccionada) {
            tokens.push(data.expoToken);
            console.log('Token añadido (tienda específica):', {
              token: data.expoToken,
              tiendasVigilante: vigilanteTiendas,
              tiendasSeleccionadas: numTiendas
            });
          }
        }
      }
    });

    console.log('Tokens encontrados:', tokens);
    return [...new Set(tokens)]; // Eliminar duplicados
  } catch (error) {
    console.error('Error en getExpoTokensForVigilantes:', error);
    throw error;
  }
};

// También necesitamos modificar sendNotificationToStore para pasar las tiendas
export const sendNotificationToStore = async (notificationTitle, notificationMessage, selectedServices, servicioId) => {
  try {
    console.log('Iniciando envío de notificación a tiendas:', {
      selectedServices,
      servicioId,
      title: notificationTitle
    });
    
    // Validar datos de entrada
    if (!notificationTitle || !notificationMessage || !selectedServices || !servicioId) {
      throw new Error('Faltan datos requeridos');
    }

    // Asegurar que selectedServices sea un array
    const tiendas = Array.isArray(selectedServices) ? selectedServices : [selectedServices];

    // Obtener tokens
    const expoTokens = await getExpoTokensForVigilantes(tiendas, servicioId);
    
    console.log('Tokens obtenidos:', expoTokens);

    if (!expoTokens || expoTokens.length === 0) {
      throw new Error('No se encontraron tokens válidos para las tiendas seleccionadas');
    }

    // Preparar datos para la función
    const notificationData = {
      tokens: expoTokens,
      title: notificationTitle,
      body: notificationMessage,
      data: {
        type: 'store',
        storeNumbers: tiendas,
        timestamp: new Date().toISOString()
      }
    };

    console.log('Enviando datos:', JSON.stringify(notificationData, null, 2));

    const response = await fetch(
      'https://us-central1-testingstuff-47ffc.cloudfunctions.net/sendPushNotification',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(notificationData)
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error en la función: ${errorText}`);
    }

    const result = await response.json();
    console.log('Respuesta de la función:', result);

    if (result.success) {
      const to = determineToValue(selectedServices);
      // Pasar las tiendas al addNotification
      await addNotification(
        notificationTitle, 
        notificationMessage, 
        to, 
        new Date().toISOString(), 
        servicioId,
        tiendas // Pasar las tiendas seleccionadas
      );
    }

    return result;
  } catch (error) {
    console.error('Error detallado:', error);
    throw new Error(error.message || 'Error al enviar notificación');
  }
};

// Y también modificar sendNotification para el caso 'all'
export const sendNotification = async (title, body, servicioId) => {
  return sendNotificationToStore(title, body, ['all'], servicioId);
};

const determineToValue = (numTiendas) => {
  if (numTiendas === 'all' || (Array.isArray(numTiendas) && numTiendas.includes('all'))) {
    return 'all';
  }
  const numTiendaArray = Array.isArray(numTiendas) ? numTiendas : [numTiendas];
  return numTiendaArray.length > 1 ? 'cliente' : 'servicio';
};

const addNotification = async (title, body, to, dateSent, servicioId, selectedServices) => {
  try {
    const auth = getAuth();
    if (!auth.currentUser) {
      throw new Error('Usuario no autenticado');
    }

    const notificationData = {
      title,
      body,
      to,
      dateSent,
      createdAt: new Date(),
      createdBy: auth.currentUser.uid,
      servicioId,
      data: {
        storeNumbers: selectedServices || [] // Agregar las tiendas seleccionadas
      },
      leida: false
    };

    console.log('Guardando notificación:', notificationData);

    const notificationsRef = collection(db, 'servicios', servicioId, 'notifications');
    const docRef = await addDoc(notificationsRef, notificationData);

    console.log('Notificación guardada con ID:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error en addNotification:', error);
    throw error;
  }
};