import JSZip from 'jszip';
import jsPDF from 'jspdf';
import logo from '../.././images/logo337.png';
import { parse, format } from 'date-fns';
import "./DownloadModal.scss";

export function getFolderName(documentType) {
    switch (documentType) {
        case 'legal':
            return 'documentos-legales';
        case 'ims':
            return 'documentos-ims';
        case '112':
            return 'documentos-112';
        case 'denuncia':
            return 'documentos-denuncias';
        case 'intervencion':
            return 'documentos-intervenciones';
        case 'parte de trabajo':
            return 'documentos-partes-trabajo';
        default:
            return 'documentos';
    }
}

/**
* Descarga las fotos seleccionadas de los documentos.
* @param {Array} documents - Documentos seleccionados.
* @param {Object} folder - Carpeta donde se guardarán las fotos en el ZIP.
*/
export async function downloadSelectedPhotos(documents, folder) {
    for (const document of documents) {
        if (document.fields && document.fields.fotos) {
            const urls = Object.values(document.fields.fotos);
            const folderName = getFolderName(document.documentTypeId);
            for (let i = 0; i < urls.length; i++) {
                const formattedDate = formatDateForFolder(document.fecha); // Formatear la fecha
                const response = await fetch(urls[i]);
                const blob = await response.blob();
                const photoFileName = `${formattedDate}-${document.tienda}-${document.id}-foto-${i}.jpg`;
                if (!folder.folder(folderName).folderExists) {
                    folder.folder(folderName);
                }
                folder.folder(folderName).file(photoFileName, blob);
            }
        }
    }
}

/**
 * Formatea la fecha de "dd/mm/aaaa" a "dd-mm-aaaa".
 * @param {string} dateString - Fecha en formato "dd/mm/aaaa".
 * @returns {string} - Fecha formateada en "dd-mm-aaaa".
 */
export function formatDateForFolder(dateString) {
    const parts = dateString.split('/');
    if (parts.length === 3) {
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];
        return `${day}-${month}-${year}`;
    }
    return dateString; // Devuelve la fecha original si el formato no es válido
}

export async function downloadSelectedDocumentsAsPDF(documents, folder, exportType) {

    const logoPath = logo; // Ruta y nombre del archivo de imagen del logo
    const logoData = await fetch(logoPath);
    const logoBlob = await logoData.blob();
    const logoUrl = URL.createObjectURL(logoBlob);
    const logoWidth = 170; // Ancho del logo en píxeles
    const logoHeight = 70; // Alto del logo en píxeles


    for (const document of documents) {
        if (
            document.documentTypeId === 'parte de trabajo' ||
            document.documentTypeId === 'intervencion' ||
            document.documentTypeId === '112' ||
            exportType === 'pdf'
        ) {
            const pdf = new jsPDF('p', 'pt', 'letter', true); // Crear documento PDF con orientación vertical y tamaño de página 'letter'

            // Configuración de márgenes
            const margin = 20;
            const contentWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
            const contentHeight = pdf.internal.pageSize.getHeight() - 2 * margin;

            // Logo
            const logoPositionX = pdf.internal.pageSize.getWidth() - margin - logoWidth; // Posición X en la esquina derecha
            const logoPositionY = margin; // Posición Y en la esquina superior
            pdf.addImage(logoUrl, 'PNG', logoPositionX, logoPositionY, logoWidth, logoHeight);

            // Agregar nombre del documento centrado en amarillo oscuro en la parte superior
            pdf.setFontSize(24);
            pdf.setTextColor('#FFBF00'); // Amarillo
            pdf.setFont('helvetica', 'bold');
            const documentName = document.documentTypeId.toUpperCase();
            const documentNameWidth = pdf.getStringUnitWidth(documentName) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            const documentNameX = pdf.internal.pageSize.getWidth() / 2 - documentNameWidth / 2;
            const documentNameY = margin + logoHeight + 50;
            pdf.text(documentName, documentNameX, documentNameY);

            // Estilos para el contenido de texto
            const smallerFontSize = 12;
            pdf.setFontSize(smallerFontSize);
            pdf.setTextColor('#000000'); // Negro
            pdf.setFont('helvetica', 'bold');

            // Dibujar los campos adicionales
            let textY = margin + 25;
            const vigilanteValue = document.vigilante.replace('@gmail.com', ''); // Remover la terminación @gmail.com
            pdf.text(`Vigilante: ${vigilanteValue}`, margin, textY + documentNameY);
            textY += 20;
            pdf.text(`Servicio: ${document.tienda || ''}`, margin, textY + documentNameY);
            textY += 20;
            pdf.text(`Servicio: ${document.cliente || ''}`, margin, textY + documentNameY);
            textY += 20;
            pdf.text(`Fecha de subida: ${document.fecha || ''}`, margin, textY + documentNameY);
            textY += 20;

            // Separación de dos líneas
            textY += 20;

            const observaciones = document.fields.observaciones || '';
            const turno = document.fields.turno || '';

            // Otros campos
            pdf.setFont('helvetica', 'normal');
            let otherFields = Object.entries(document.fields)
                .filter(([field, value]) => field !== 'observaciones' && field !== 'turno' && field !== 'FECHA' && field !== 'fotos')
                .map(([field, value]) => `${capitalizeFirstLetter(field)}: ${value}`)
                .join('\n\n');

            // Agregar turno si está presente
            if (turno) {
                otherFields += `\n\n${capitalizeFirstLetter('turno')}: ${turno}`;
            }

            // Agregar observaciones si está presente
            if (observaciones) {
                otherFields += `\n\n${capitalizeFirstLetter('observaciones')}: ${observaciones}`;
            }
            

            const otherText = otherFields;
            // let fotoY = textY + documentNameY + 40; // Espacio después del texto

            const options = { width: contentWidth, align: 'left' };
            const lines = pdf.splitTextToSize(otherText, contentWidth);
            // Mover la posición del texto después de las imágenes
            textY += 150;
            pdf.text(lines, margin, textY, options);


            // Agregar footer amarillo
            const footerHeight = 10;
            pdf.setFillColor('#FFBF00'); // Amarillo
            pdf.rect(margin, pdf.internal.pageSize.getHeight() - margin - footerHeight, contentWidth, footerHeight, 'F');

            // Configurar color de texto en blanco
            pdf.setTextColor('#FFFFFF'); // Blanco

            pdf.setFontSize(10);
            // pdf.text(footerText, margin + 10, pdf.internal.pageSize.getHeight() - margin - footerHeight + 15);

            // Después de agregar el contenido principal del PDF pero antes de guardar el PDF
            // Agregar una nueva página
            pdf.addPage();

            pdf.addImage(logoUrl, 'PNG', logoPositionX, logoPositionY, logoWidth, logoHeight);
            // Configurar color de texto en blanco para la nueva página
            pdf.setTextColor('#000000'); // Negro

            
            // Verificar si hay fotos y agregarlas al PDF si existen
            const fotos = document.fields.fotos;
            if (fotos && typeof fotos === 'object') {
                let fotoIndex = 0;
                const fotoHeight = 250; // Altura fija de la imagen
                const fotoWidth = 250; // Ancho fijo de la imagen
                let fotoX = 50;
                let fotoY =  100; // Espacio después del texto
                for (const key in fotos) {
                    if (Object.hasOwnProperty.call(fotos, key)) {
                        const fotoUrl = fotos[key];
                        const fotoData = await fetch(fotoUrl);
                        const fotoBlob = await fotoData.blob();
                        const fotoUrlForPdf = URL.createObjectURL(fotoBlob);
                        // Ajustar la posición de la imagen
                        if (fotoX + fotoWidth > contentWidth + margin) {
                            fotoX = margin;
                            fotoY += fotoHeight + 10; // Espacio entre las imágenes
                        }
                        pdf.addImage(fotoUrlForPdf, 'JPEG', fotoX, fotoY, fotoWidth, fotoHeight);
                        fotoX += fotoWidth + 10; // Espacio entre las imágenes
                    }
                }
            }

            // SEGUNDA PÁGINA: Firmas
            pdf.addPage();
            pdf.addImage(logoUrl, 'PNG', logoPositionX, logoPositionY, logoWidth, logoHeight);

            let currentY = logoPositionY + logoHeight + 50;

            // Título de la sección de firmas
            pdf.setFontSize(16);
            pdf.setTextColor('#000000');
            pdf.setFont('helvetica', 'bold');
            pdf.text('Firmas del documento', margin, currentY);
            currentY += 40;

            // Procesar firmas
            for (const [field, value] of Object.entries(document.fields)) {
                if (typeof value === 'string' && value.includes('signatures')) {
                    try {
                        const signatureResponse = await fetch(value);
                        const signatureBlob = await signatureResponse.blob();
                        const signatureUrl = URL.createObjectURL(signatureBlob);

                        // Título del campo de firma
                        pdf.setFontSize(14);
                        pdf.setTextColor('#000000');
                        pdf.setFont('helvetica', 'bold');
                        pdf.text(capitalizeFirstLetter(field), margin, currentY);
                        currentY += 25;

                        // Agregar la firma
                        const signatureWidth = 200;
                        const signatureHeight = 100;
                        pdf.addImage(signatureUrl, 'PNG', margin, currentY, signatureWidth, signatureHeight);
                        currentY += signatureHeight + 40;

                        URL.revokeObjectURL(signatureUrl);
                    } catch (error) {
                        console.error('Error al procesar la firma:', error);
                    }
                }
            }

            // Agregar texto al nueva página
            const footerText = `
                                                                      CLAUSULA LEGAL

    La información contenida en este documento es confidencial y está destinada a ser leída solo por la(s) persona(s)
    a la(s) que va dirigida. Si usted lee este documento y no es su destinatario o no está expresamente autorizado
    para leerlo, le informamos que pueda ser ilegal cualquier divulgación, distribución o reproducción del mismo,
    por lo que le instamos a que nos lo notifique inmediatamente y proceda a su destrucción.
    Recuerde en todo caso que, quienes intervengan en cualquier fase del tratamiento de datos de carácter personal
    están obligados al secreto profesional respecto de los mismos y al deber de guardarlos, obligaciones que subsistirán
    aún después de finalizar sus relaciones con el titular del fichero de datos o, en su caso, con el responsable del
    mismo (Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos 
    digitales).                  
`;
            pdf.setFontSize(10);
            pdf.text(footerText, margin + 10, margin + 600); // Ajusta las coordenadas según tu diseño

            pdf.setFillColor('#FFBF00'); // Amarillo
            pdf.rect(margin, pdf.internal.pageSize.getHeight() - margin - footerHeight, contentWidth, footerHeight, 'F');

            const pdfBytes = pdf.output('arraybuffer');

            const documentTypeFolder = folder.folder(getFolderName(document.documentTypeId));

            const moment = require('moment');
            const formattedDate = moment(document.fecha, 'D/M/YYYY').format('D-M-YYYY');

            documentTypeFolder.file(`${formattedDate}-${document.tienda}-${document.id}.pdf`, pdfBytes);
        }
    }
}



export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


/**
* Descarga los documentos seleccionados como archivos CSV dentro de una carpeta ZIP.
* @param {Array} documents - Documentos seleccionados.
* @param {Object} folder - Carpeta donde se guardarán los archivos en el ZIP.
*/
export function downloadSelectedCSV(documents, folder, exportType) {
    const csvDocs = documents.filter((doc) => doc.documentTypeId === "112");
    if (csvDocs.length > 0 || exportType === 'csv') {
        // Agrupar los documentos 112 en uno solo
        const header = Object.keys(csvDocs[0].fields);
        header.unshift("fecha");
        header.unshift("vigilantes");
        header.unshift("tiendas");

        const csvRows = [];
        for (const document of csvDocs) {
            // Agregar la fila correspondiente al CSV
            const values = Object.values(document.fields);
            const rowData = values.map((value) => {
                return typeof value === "string" ? value : JSON.stringify(value);
            });
            const formattedDate = formatDateForFolder(document.fecha); // Formatear la fecha
            rowData.unshift(formattedDate);
            rowData.unshift(document.vigilante);
            rowData.unshift(document.tienda);
            csvRows.push(rowData.join(","));
        }

        // Convertir el arreglo csvRows en una cadena separada por saltos de línea
        const csvContent = [header.join(","), ...csvRows].join("\n");

        const folderName = getFolderName(csvDocs[0].documentTypeId);
        folder = folder.folder(folderName);
        folder.file(`${folderName}.csv`, csvContent);
    } else {
        console.log("No hay documentos del tipo 112 seleccionados.");
    }
}

/**
 * Checks if a given date falls within the last N days.
 *
 * @param {Date|number|string} date - The date to be checked. Can be a Date object, timestamp, or date string.
 * @param {number} n - The number of days to consider for the "last N days" comparison.
 * @returns {boolean} True if the date is within the last N days, otherwise false.
 */
export function isWithinLastNDays(date, n) {
    const today = new Date();
    const targetDate = new Date(date);
    const differenceInDays = (today - targetDate) / (1000 * 60 * 60 * 24);

    return differenceInDays <= n;
}

/**
 * Formats a date string in the 'dd/MM/yyyy' format into a JavaScript Date object.
 *
 * @param {string} dateString - The date string to be formatted.
 * @returns {Date} A JavaScript Date object representing the formatted date.
 */
export const formatDate = (dateString) => {
    // Parse the date using the expected format
    return parse(dateString, 'dd/MM/yyyy', new Date());
};

/**
 * Applies a date-based filter to determine if a document's date falls within the specified range.
 *
 * @param {Object} row - The document row being evaluated.
 * @param {Date} documentDate - The date associated with the document.
 * @param {string} dateFilter - The selected date filter option (e.g., "all", "last3Days", "lastWeek", "today").
 * @returns {boolean} - True if the document passes the date filter, false otherwise.
 */
export const applyDateFilter = (row, documentDate, dateFilter) => {
    const today = new Date();
    switch (dateFilter) {
        case "all":
            return true;

        case "last3Days":
            return isWithinLastNDays(documentDate, 3);

        case "lastWeek":
            return isWithinLastNDays(documentDate, 7); // Use isWithinLastNDays for last 7 days

        case "today":
            const startOfToday = new Date();
            startOfToday.setHours(0, 0, 0, 0);
            return documentDate >= startOfToday;

        default:
            return true; // Return a default value if needed
    }
};

/**
 * Componente de modal para mostrar el estado de la descarga.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.isOpen - Indica si el modal está abierto.
 * @param {Function} props.onClose - Función para cerrar el modal.
 * @param {number} props.progress - Progreso de la descarga en porcentaje.
 * @param {string} props.feedback - Retroalimentación de la descarga.
 * @returns {JSX.Element} - Elemento JSX que representa el modal.
 */
export const DownloadModal = ({ isOpen, onClose, progress, feedback }) => (
    <div className={`download-modal ${isOpen ? "open" : ""}`}>
        <div className="modal-content">
            <h3>Estado de la descarga</h3>
            {progress !== 0 && (
                <>
                    <p>Descargando...</p>
                    <p>Progreso: {progress.toFixed(2)}%</p>
                </>
            )}
            {feedback && <p>{feedback}</p>}
            <button onClick={onClose}>Aceptar</button>
        </div>
    </div>
);