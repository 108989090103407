import React, { useState, useEffect, useContext, memo } from 'react';
import { sendNotification, sendNotificationToStore } from './NotificationHandler';
import { AuthContext } from '../../context/AuthContext';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import './Notifications.scss';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const SelectServices = memo(({ selectedServices, services, setSelectedServices }) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    };

    return (
        <div className="form-group">
            <label htmlFor="serviceSelect">Selecciona los servicios:</label>
            <Select
                id="serviceSelect"
                value={selectedServices}
                onChange={(e) => {
                    const value = e.target.value;
                    if (value[value.length - 1] === "all") {
                        setSelectedServices(
                            selectedServices.length === services.length ? 
                            [] : 
                            services.map(service => service.numTienda)
                        );
                    } else {
                        setSelectedServices(value);
                    }
                }}
                multiple
                className="input-notis"
                sx={{ background: 'white' }}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.length === services.length ? (
                            <Chip label="Todos los servicios" />
                        ) : (
                            selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))
                        )}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                <MenuItem value="all">
                    <em>Seleccionar Todos</em>
                </MenuItem>
                {services.map((service) => (
                    <MenuItem key={service.id} value={service.numTienda}>
                        {service.numTienda}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
});

const NotificationsCombined = () => {
    const { currentService, adminData } = useContext(AuthContext);
    const servicioId = currentService ? currentService.id : null;

    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationBody, setNotificationBody] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const fetchClients = async () => {
        try {
            if (adminData && adminData.clientes) {
                const clientsCollection = collection(db, 'servicios', servicioId, 'clientes');
                const clientsSnapshot = await getDocs(clientsCollection);

                const clientsData = clientsSnapshot.docs
                    .filter(doc => adminData.clientes.includes(doc.id))
                    .map((doc) => ({ id: doc.id, ...doc.data() }));
                setClients([{ id: 'all', nombre: 'Todos los clientes' }, ...clientsData]);
            }
        } catch (error) {
            console.error('Error al obtener los clientes:', error);
        }
    };

    const handleClientChange = async (e) => {
        const selectedClientId = e.target.value;
        setSelectedClient(selectedClientId);

        if (selectedClientId === 'all') {
            try {
                // Obtener todas las tiendas cuando se selecciona "Todos los clientes"
                const tiendasRef = collection(db, 'servicios', servicioId, 'tiendas');
                const tiendasSnapshot = await getDocs(tiendasRef);
                const tiendasData = tiendasSnapshot.docs.map((doc) => doc.data());
                setServices(tiendasData);
                // Seleccionar automáticamente todas las tiendas
                const allServices = tiendasData.map(tienda => tienda.numTienda);
                setSelectedServices(allServices);
            } catch (error) {
                console.error('Error al obtener todas las tiendas:', error);
            }
        } else if (selectedClientId) {
            try {
                // Obtener las tiendas filtradas por el cliente seleccionado
                const tiendasRef = collection(db, 'servicios', servicioId, 'tiendas');
                const tiendasQuery = query(
                    tiendasRef,
                    where('cliente', '==', selectedClientId)
                );
                const tiendasSnapshot = await getDocs(tiendasQuery);
                const tiendasData = tiendasSnapshot.docs.map((doc) => doc.data());
                setServices(tiendasData);
                // Limpiar selección de servicios
                setSelectedServices([]);
            } catch (error) {
                console.error('Error al obtener las tiendas:', error);
            }
        } else {
            setServices([]);
            setSelectedServices([]);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        
        try {
            console.log('Enviando notificación:', {
                title: notificationTitle,
                body: notificationBody,
                selectedServices,
                servicioId
            });

            if (!currentService) {
                throw new Error('No se ha encontrado un servicio asociado al usuario.');
            }

            if (!notificationTitle || !notificationBody) {
                throw new Error('Por favor, complete todos los campos.');
            }

            if (selectedClient === 'all') {
                await sendNotification(notificationTitle, notificationBody, servicioId);
            } else if (selectedServices && selectedServices.length > 0) {
                await sendNotificationToStore(notificationTitle, notificationBody, selectedServices, servicioId);
            } else {
                throw new Error('Por favor, seleccione al menos un servicio.');
            }

            setNotificationTitle('');
            setNotificationBody('');
            setSelectedServices([]);
            setSnackbarSeverity('success');
            setSnackbarMessage('Notificación enviada correctamente');
            setSnackbarOpen(true);

        } catch (error) {
            console.error('Error al enviar notificación:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage(error.message || 'Error al enviar la notificación');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (!servicioId) {
            console.error('servicioId es undefined o null');
            return;
        }

        fetchClients();
    }, [servicioId, adminData]);

    return (
        <div className="notifications-container">
            <form className="notification-form" onSubmit={handleFormSubmit}>
                <h3 className="label-title-form">Enviar notificación</h3>
                <div className="form-group">
                    <label htmlFor="notificationTitle">Título de la notificación:</label>
                    <input
                        type="text"
                        id="notificationTitle"
                        value={notificationTitle}
                        onChange={(e) => setNotificationTitle(e.target.value)}
                        placeholder="Escribe el título de la notificación"
                        className="input-notis"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="notificationBody">Cuerpo de la notificación:</label>
                    <textarea
                        id="notificationBody"
                        value={notificationBody}
                        onChange={(e) => setNotificationBody(e.target.value)}
                        placeholder="Escribe el cuerpo de la notificación"
                        rows="5"
                        cols="50"
                        className="input-notis"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="clientSelect">Selecciona el cliente:</label>
                    <select 
                        id="clientSelect" 
                        value={selectedClient} 
                        onChange={handleClientChange} 
                        className="input-notis"
                    >
                        <option value="">Selecciona un cliente</option>
                        {clients.map((client) => (
                            <option key={client.id} value={client.id}>
                                {client.nombre}
                            </option>
                        ))}
                    </select>
                </div>
                <SelectServices 
                    selectedServices={selectedServices} 
                    services={services} 
                    setSelectedServices={setSelectedServices}
                />
                <button 
                    type="submit" 
                    className="submit-button"
                    disabled={!notificationTitle || !notificationBody || (!selectedClient && selectedServices.length === 0)}
                >
                    Enviar Notificación
                </button>
            </form>
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={6000} 
                onClose={handleSnackbarClose}
            >
                <MuiAlert 
                    elevation={6} 
                    variant="filled" 
                    onClose={handleSnackbarClose} 
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default NotificationsCombined;